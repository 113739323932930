import { GridContainer } from "@pmi/ui-react";
import { FunctionComponent } from "react";
import styles from "./Preloader.module.scss";

type PreloaderProps = {
  hide?: boolean,
}

const Preloader: FunctionComponent<PreloaderProps> = ({ hide }: PreloaderProps) => (
  <GridContainer className={`${styles.preloader} ${hide ? "" : styles.hide}`}>
    <svg width="48" viewBox="25 25 50 50">
      <circle cx="50" cy="50" r="20" fill="none" strokeWidth="5" stroke="var(--color-pmi)" strokeLinecap="round" strokeDashoffset="0" strokeDasharray="100, 200">
        <animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 50 50" to="360 50 50" dur="2.5s" repeatCount="indefinite" />
        <animate attributeName="stroke-dashoffset" values="0;-30;-124" dur="1.25s" repeatCount="indefinite" />
        <animate attributeName="stroke-dasharray" values="0,200;110,200;110,200" dur="1.25s" repeatCount="indefinite" />
      </circle>
    </svg>
  </GridContainer>
);

export default Preloader;
