import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const container = document.getElementById("root");
if (container !== null) {
  const root = createRoot(container);

  root.render(
    <StrictMode>
      <Router>
        <Suspense fallback={<></>}>
          <App />
        </Suspense >
      </Router>
    </StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
