import "../scss/preloader.scss";

const styles: { [key: string]: React.CSSProperties } = {
  logo: {
    position: 'fixed',
    width: 66,
    height: 65,
    top: 'calc(50% - 65px / 2)',
    left: 'calc(50% - 66px / 2)',
  },
  container: {
    background: 'var(--color-soft-white)',
    zIndex: 8000,
  }
}

export default function Preloader() {
  return (
    <div className="preloader" style={styles.container}>
      <svg width="66" height="65" viewBox="0 0 66 65" fill="none" xmlns="http://www.w3.org/2000/svg" style={styles.logo}>
        <path fillRule="evenodd" clipRule="evenodd" d="M32.6017 0.996094C39.1638 0.996094 45.2149 4.64706 48.4279 10.5449L62.7142 36.3728C69.7459 49.092 60.9514 64.9961 46.8879 64.9961H18.3154C4.23259 64.9961 -4.56193 49.092 2.4795 36.3728L16.7754 10.5449C19.9884 4.64706 26.0396 0.996094 32.6017 0.996094ZM9.84756 47.1661C13.7176 56.5477 22.7029 62.5895 32.5921 62.4599V62.4798C39.0175 62.4402 45.1641 59.7698 49.6785 55.0566C54.1928 50.3434 56.7046 43.9739 56.6608 37.3508C56.6679 27.1564 50.6991 17.9677 41.5519 14.0913C32.4048 10.215 21.892 12.4192 14.9408 19.6709C7.98957 26.9226 5.97748 37.7846 9.84756 47.1661Z" fill="#00D1D2" />
        <circle className="preloader-inner" cx="32" cy="37.5" r="24" />
      </svg>
    </div>
  );
}