import { FunctionComponent, Suspense, lazy } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import SdsPreloader from "./Sds/components/Preloader";
import Preloader from "./SelfRegistration/components/Preloader";
import "./scss/index.scss";

const Sds = lazy(() => import("./Sds/App"));
const SdsLil = lazy(() => import("./SdsLil/App"));
const Heets = lazy(() => import("./Heets/App"));
const Vgt = lazy(() => import("./Vgt/App"));
const Catm = lazy(() => import("./Catm/App"));
const Catm2 = lazy(() => import("./Catm2/App"));
const CatmLg = lazy(() => import("./CatmLg/App"));
const Invitation = lazy(() => import("./Invitation/App"));
const NotFound = lazy(() => import("./Heets/pages/Error"));
const Mcc = lazy(() => import("./Mcc"));
const SelfRegistration = lazy(() => import("./SelfRegistration"));
const Oba = lazy(() => import("./Oba"));
const Ny = lazy(() => import("./Ny"));
const DeviceInstructions = lazy(() => import("./DeviceInstructions"));
const LmLanding = lazy(() => import("./LmLanding"));
const FeedbackSurvey = lazy(() => import("./FeedbackSurvey"));

const App: FunctionComponent = () => {
  const location = useLocation();

  return (
    <Suspense fallback={
      location.pathname === "/survey/self-registration" ?
        <Preloader /> : <SdsPreloader />
    }>
      <Routes>
        <Route path="/survey/sds" element={<Sds />} />
        <Route path="/survey/sds-lil" element={<SdsLil />} />
        <Route path="/survey/vgt" element={<Vgt />} />
        <Route path="/survey/catm" element={<Catm />} />
        <Route path="/survey/catm/:id" element={<Catm />} />
        <Route path="/survey/catm-lg/:id" element={<CatmLg />} />
        <Route path="/form/catm/:referralCode" element={<Catm2 />} />
        <Route path="/survey/heets" element={<Heets />} />
        <Route path="/survey/invitation/:param" element={<Invitation />} />
        <Route path="/survey/cc" element={<Mcc />} />
        <Route path="/survey/self-registration" element={<SelfRegistration />} />
        <Route path="/webinar/oba" element={<Oba />} />
        <Route path="/survey/ny-quiz" element={<Ny />} />
        <Route path="/survey/ny-quiz-se" element={<Ny />} />
        <Route path="/device-instructions/:model" element={<DeviceInstructions />} />
        <Route path="/form/lm" element={<LmLanding />} />
        <Route path="/feedback-survey" element={<FeedbackSurvey />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default App;